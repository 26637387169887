<template>
  <v-popover
    popoverClass="popover-link"
    :placement="'top'"
    trigger="hover"
    offset="0px"
    :handleResize="true"
  >
    <slot></slot>
    <template slot="popover">
      <div class="popover-content">
        <router-link
          :to="'/projects/' + dealId"
          target="_blank"
          class="tooltip-link-content"
        >
          {{ name }}
          <font-awesome-icon class="icon" icon="external-link-alt" />
        </router-link>
        <div class="copy-to-clipboard" @click="copyToClipboard(name)">
          <font-awesome-icon class="icon" icon="copy" />
        </div>
      </div>
      <div class="tooltip-actions" v-if="currentUser.isSuper">
        <hr />
        <div class="tooltip-action">
          SAV :
          <b-form-checkbox
            switch
            :checked="sav"
            @change="handleSwitchChange()"
          ></b-form-checkbox>
        </div>
      </div>
      <input ref="textToCopy" type="hidden" :value="name" />
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'
Vue.component('v-popover', VPopover)

export default {
  name: 'TooltipEdit',
  props: {
    placement: {
      type: String,
      default: 'top'
    },
    name: {
      type: String,
      required: true
    },
    dealId: {
      required: true
    },
    lead: {
      required: true
    }
  },
  data() {
    return {
      sav: this.lead.sav === 1 ? true : false
    }
  },
  methods: {
    ...mapActions(['changeSAV']),
    copyToClipboard(text) {
      const el = document.createElement('textarea')
      el.value = text
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)

      this.$bvToast.toast(text, {
        title: 'Texte copié',
        autoHideDelay: 2000,
        toaster: 'b-toaster-bottom-right',
        variant: 'success',
        solid: true
      })
    },
    async handleSwitchChange() {
      await this.changeSAV(this.lead)
      this.$nextTick(() => {
        this.sav = this.lead.sav === 1 ? true : false
      })
      this.$emit('changeSav')
    }
  },
  computed: {
    ...mapGetters(['currentUser'])
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-link {
    opacity: 1;
    border-radius: 2px;
    border: none;
    .tooltip-inner {
      max-width: 200px;
      padding: 4px 8px;
      color: #fff;
      text-align: center;
      background-color: #6c757d;
      border-radius: 2px;
      .popover-content {
        display: flex;
        .tooltip-link-content {
          display: flex;
          align-items: center;
          font-size: 10px;
          font-weight: 400;
          color: #fff;
          svg {
            margin-left: 6px;
          }
        }
        .copy-to-clipboard {
          font-size: 15px;
          margin-left: 10px;
          padding: 0px 8px;
          border: 1px solid #fff;
          border-radius: 3px;
          cursor: pointer;
          height: 25px;
          &:hover {
            color: #6c757d;
            background-color: #fff;
          }
        }
      }
      .tooltip-actions {
        hr {
          margin-bottom: 5px;
          margin-top: 8px;
          border-color: #fff;
        }
        .tooltip-action {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 2px 0px 2px 10px;
        }
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #2dabe2;
    background-color: #2dabe2;
  }
}
</style>
